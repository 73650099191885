import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/App-BG-Final.jpg';
import logoImage from '../../assets/TKM_Green.png';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SignIn() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [countryCode, setCountryCode] = useState('+91'); // Default country code
  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState(new Array(6).fill('')); // Array to hold OTP digits

  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleGetOtp = async () => {
    // Remove hyphens before sending
    const formattedNumber = mobileNumber.replace(/-/g, '');
    if (formattedNumber.length !== 10) {
      alert("Please enter a valid 10-digit mobile number");
      return;
    }

    try {
      const response = await fetch('https://api.kolkatajamaat.com/api/get_otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: countryCode + formattedNumber }),
      });

      if (response.ok) {
        setShowOtpInput(true); // Shows OTP input after the OTP is sent
      } else {
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  // Format mobile number as XXX-XXX-XXXX
  const formatMobileNumber = (value) => {
    // Remove all non-numeric characters
    const cleaned = ('' + value).replace(/\D/g, '');
    // Add hyphens after 3 and 6 digits
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join('-');
    }
    return value;
  };

  const handleMobileNumberChange = (e) => {
    const formattedNumber = formatMobileNumber(e.target.value);
    setMobileNumber(formattedNumber);
  };

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (/^\d$/.test(value)) { // Only allow digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move to the next input if a digit is entered
      if (element.nextSibling) {
        element.nextSibling.focus();
      }

      // If all 6 digits are filled, trigger the login function
      if (newOtp.every((digit) => digit !== '')) {
        handleLogin(newOtp.join(''));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const newOtp = [...otp];
      newOtp[index] = ''; // Clear the current box
      setOtp(newOtp);

      // Move to previous input if backspace is pressed and the current box is empty
      if (e.key === 'Backspace' && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }

      // Prevent the default backspace behavior in browsers
      e.preventDefault();
    }
  };

  const handleLogin = async (otpString) => {
    try {
      const formattedNumber = mobileNumber.replace(/-/g, ''); // Remove hyphens
      const response = await fetch(`https://api.kolkatajamaat.com/api/login/${otpString}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: countryCode + formattedNumber }),
      });

      if (response.ok) {
        const tokenData = await response.json();
        localStorage.setItem('token', tokenData.data.token);
        setToken(tokenData.data.token);
        navigate('/dashboard');
      } else {
        alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-cover bg-center bg-[burlywood]" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className=" p-6 rounded-lg w-full max-w-md" >
        <div className="flex justify-center mb-6 mt-[-50px]">
          <img
            src={logoImage}  // Replace this with your logo's URL or path
            alt="TKM Logo"
            className="rounded-full object-cover "
            style={{ width: '80vw' }}  // Circular shape
          />
        </div>
        <h1 className="text-2xl font-bold mb-4 text-center mt-[-25px] text-[#045c34]">TKM SCANNING</h1>

        {/* Mobile Input Section */}
        <div className="mb-4 mt-[10px]">
          {/* <label className="block mb-2 mb-5 text-gray-800 text-[darkslategrey]" hidden={showOtpInput}>Please Sign In</label> */}
          <div className="mb-4 flex mt-10">
            <select
              className="border rounded-l-md px-3 py-2 bg-gray-100 text-xl text-[darkslategrey] focus:outline-none" // Remove focus outline
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              hidden={showOtpInput} // Disable the country code input once OTP is shown
            >
              <option value="+91">🇮🇳 +91</option>
              {/* Add more country codes as needed */}
            </select>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-r-md text-xl text-[darkslategrey] focus:outline-none" // Remove focus outline
              placeholder="Enter Mobile"
              value={mobileNumber}
              onChange={handleMobileNumberChange} // Handle mobile number formatting
              maxLength="12" // Adjust max length for 12 characters (XXX-XXX-XXXX)
              hidden={showOtpInput} // Disable mobile number input once OTP is shown
            />
          </div>
        </div>

        {/* Show OTP Input if OTP is Sent */}
        {showOtpInput && (
          <div className="mb-4">
            
            <div className="flex space-x-[11px] justify-center">
              {Array.from({ length: 6 }).map((_, index) => (
                <input
                  key={index}
                  type="tel" // Type 'tel' to show number keypad on mobile
                  maxLength={1} // Only allow 1 digit per input
                  className="w-14 h-14 border-2 border-gray-300 focus:shadow-md rounded-full text-center text-2xl focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"// Remove focus outline
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace or delete navigation
                />
              ))}
            </div>
            <h1 className="text-2xl mb-4 text-center mt-10">Enter OTP</h1>
          </div>
        )}

        {/* Button for OTP and Login */}
        {!showOtpInput ? (
          <button className="flex items-center justify-center w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none mb-8 mt-5"
                  onClick={handleGetOtp}
            >
              <FontAwesomeIcon icon={faWhatsapp} className="h-6 w-6 mr-2" />
              Get OTP
          </button>
        ) : (
          <button
            className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 mt-4 mb-5 focus:outline-none mb-8" // Remove focus outline
            onClick={() => handleLogin(otp.join(''))}
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}

export default SignIn;
