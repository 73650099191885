import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundImage from '../assets/App-BG-Final.jpg';
import logoImage from '../assets/TKM_Green.png';

function Dashboard() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [itsNumber, setItsNumber] = useState('');
  const [mumininData, setMumininData] = useState(null);
  const [recordCounts, setRecordCounts] = useState({
    total_record: 0,
    total_male_record: 0,
    total_female_record: 0
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [gender, setGender] = useState('');
  const navigate = useNavigate();
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      toast.error('You must be logged in to access the dashboard.');
      navigate('/signin');
      return;
    }
  
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://api.kolkatajamaat.com/api/events', {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        });
        const result = await response.json();
        if (result && Array.isArray(result.data)) {
          setEvents(result.data);
        } else {
          setEvents([]);
        }
      } catch (error) {
        console.error('Error fetching events:', error.message);
        setEvents([]);
      }
    };
  
    fetchEvents();
  }, [navigate]);
  

  const handleDashboardSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEvent || itsNumber.length !== 8 || !gender) {
        toast.error('Please ensure all fields are correctly filled.');
        return;
    }
    const storedToken = localStorage.getItem('token');
    try {
        const response = await fetch(`https://api.kolkatajamaat.com/api/get_scan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedToken}`
            },
            body: JSON.stringify({ event_id: selectedEvent, its: itsNumber, gender: gender })
        });
        const text = await response.text(); // First get the response as text

        // Log the raw text for debugging
        console.log("Raw response text:", text);

        // Check if the text is likely JSON (simple check for JSON structure)
        if (text.trim().startsWith("{")) {
            const result = JSON.parse(text); // Attempt to parse it as JSON
            if (response.ok) {
                setMumininData(result.data[0]);
                setRecordCounts(result.record_counts[0]);
                setSubmissionStatus(result.message);
                toast.success(result.message);
                setShowStatus(true);
                
                // Reset form fields after successful submission
                // setSelectedEvent('');
                // setGender('');
                // setItsNumber('');
                
            } else {
                throw new Error(result.message || 'Failed to submit data');
            }
        } else {
            // Handle non-JSON response or extract error message
            throw new Error('Server response was not valid JSON: ' + text);
        }
    } catch (error) {
        console.error('Submission failed:', error);
        toast.error(error.message);
        setSubmissionStatus(error.message);
    } finally {
      // Reset ITS number field regardless of the outcome
      setItsNumber('');
      setShowStatus(true);
      setTimeout(() => {
        setShowStatus(false);
      }, 15000);
    }
};

const handleLogout = () => {
  localStorage.removeItem('token');
  toast.info('Logged out successfully.');
  navigate('/signin');
};

// Format the current time for display (e.g., 14:45:30)
const formattedTime = currentDate.toLocaleTimeString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false, // Use 24-hour format
});
 // Format the current date for display
 const formattedDate = currentDate.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
});
// Format the date for the `dateTime` attribute
const isoDate = currentDate.toISOString().split('T')[0]; // Get only the YYYY-MM-DD part


  return (
    <div className="flex justify-center items-center bg-cover bg-center h-screen" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="p-6 rounded-lg max-w-md w-full" style={{ height: `100%`}}>
        <div className="flex items-center justify-between gap-x-4 mt-[-15px] text-xs">
          <p className="relative z-10 rounded-full bg-gray-500 px-3 py-1.5 font-medium text-white"
            >
            <time dateTime={isoDate} className="text-white">{formattedDate} | {formattedTime}</time>
          </p>
          <a href=""
            className="relative z-10 rounded-full bg-red-400 px-3 py-1.5 font-medium text-white"
            onClick={handleLogout}
          >
            Logout
          </a>
        </div>
        <div className="flex justify-center mb-6 mt-[-10px]">
          <img
            src={logoImage}  // Replace this with your logo's URL or path
            alt="TKM Logo"
            className="rounded-full object-cover "
            style={{ width: '80vw' }}  // Circular shape
          />
        </div>
        <h1 className={`text-2xl font-bold mb-4 text-center mt-[-25px] ${showStatus ? 'text-white bg-red-500' : 'text-[#045c34]'} ${showStatus ? 'fade-out' : 'fade-in'}`}>
            {showStatus ? submissionStatus : 'TKM SCANNING'}
      </h1>
        <form onSubmit={handleDashboardSubmit}>
          <div className="mb-4">
            <select
              className="w-full px-3 py-2 border rounded-md appearance-none bg-gray-200 focus:outline-none text-gray-700"
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
            >
              <option value="">Select an Event</option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>{event.event_name}</option>
              ))}
            </select>
          </div>
          {selectedEvent && (
            <div className="mb-4">
              <div className="flex items-center justify-evenly ml-[-45px]">Gender   : 
                <label className="flex items-center mr-4 ">  
                  <input
                    type="checkbox"
                    checked={gender === 'male'}
                    onChange={() => setGender(gender === 'male' ? '' : 'male')}
                    className="form-checkbox h-5 w-5 text-blue-500 focus:ring-green-500 border-gray-300 rounded mr-[10px]"
                  />
                  Male
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={gender === 'female'}
                    onChange={() => setGender(gender === 'female' ? '' : 'female')}
                    className="form-checkbox h-5 w-5 text-blue-500 focus:ring-green-500 border-gray-300 rounded mr-[10px]"
                  />
                  Female
                </label>
              </div>
            </div>
          )}
          {selectedEvent && gender && (
            <div className="mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 border space-x-2 font-bold rounded-md bg-gray-200 focus:outline-none text-gray-700 text-xl"
                placeholder="Enter ITS No."
                value={itsNumber}
                onChange={(e) => setItsNumber(e.target.value.replace(/[^0-9]/g, ''))}
                maxLength="8"
                style={{ letterSpacing: '2px' }}
              />
            </div>
          )}

          <button
            type="submit"
            disabled={!selectedEvent || itsNumber.length !== 8 || !gender}
            className="w-full bg-green-500 text-center text-white py-2 rounded-md hover:bg-green-700 disabled:bg-gray-400"
          >
            Submit
          </button>
        </form>

        {submissionStatus && (
          <div className="text-center mt-4 p-4 bg-green-900 text-white rounded">
            {/* <p className="text-sm font-bold">Status: {submissionStatus}</p> */}
            {/* <p>Total records: {recordCounts.total_record}</p>
            <p>Male records: {recordCounts.total_male_record}</p>
            <p>Female records: {recordCounts.total_female_record}</p> */}
            {mumininData && (
              <>
                <p className='flex items-center justify-between'><span>ITS:</span> <span>{mumininData.its}</span></p>
                <p className='flex items-center justify-between'><span>Name: </span><span>{mumininData.name}</span></p>
                <p className='flex items-center justify-between'><span>Gender:</span> <span>{mumininData.gender}</span></p>
              </>
            )}
          </div>
        )}
        <div className="bg-red-900 text-white p-2 font-bold rounded mt-[20px] pb-10px">
          <p>Total records: {recordCounts.total_record}</p>
          <p>Male records: {recordCounts.total_male_record}</p>
          <p>Female records: {recordCounts.total_female_record}</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
